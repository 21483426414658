// Old routes
export const ROUTE_CART = "cart"; // @FIXME: Let's remove this from here to somewhere appropriate

export const ROUTE_AUTHENTICATION = "authentication";
export const ROUTE_BROWSE = "browse";
export const ROUTE_SEARCH = "search";
export const ROUTE_VOCABULARY = "vocabulary";
export const ROUTE_SPEAKING = "speaking";
export const ROUTE_LESSONS = "lessons";
export const ROUTE_VIDEO = "video";
export const ROUTE_VIDEOS = "videos";
export const ROUTE_COURSE = "course";
export const ROUTE_COURSES = "courses";
export const ROUTE_COURSEV1 = "coursev1";
export const ROUTE_FORGOT_PASSWORD = "forgot-password";
export const ROUTE_RESET_PASSWORD = "login/reset";
export const ROUTE_MY_ENGLISH = "myenglish";
export const ROUTE_MY_CLASS = "myclass";
export const ROUTE_STUDENT = "student";
export const ROUTE_STUDENTS = "students";
export const ROUTE_PROFILE_SETTINGS = "profile-settings";
export const ROUTE_PROFILE_SETTINGS_GENERAL = "profile-settings/general";
export const ROUTE_PAYMENT = "payment";
export const ROUTE_PAYMENT_CUSTOM = "payment-custom";
export const ROUTE_PAYMENT_CHECKOUT = "payment-checkout";
export const ROUTE_PAYMENT_SELECT_METHOD = "payment-select-method";
export const ROUTE_RANKINGS = "rankings";
export const ROUTE_LESSON_PLAN = "videodetails";
export const ROUTE_LESSON_REPORT = "lesson-report";
export const ROUTE_TEACHER_TOOLS = "teacher-tools";
export const ROUTE_KIRIHARA_TEACHER_TOOLS = "kirihara-teacher-tools";
export const ROUTE_VLT_TEACHER_TOOLS = "teacher-tools";
export const ROUTE_COMPANY_INFORMATION = "company-information";
export const ROUTE_COMPANY_INFORMATION_NEWS = "company-information/news";
export const ROUTE_INDIVIDUAL_SUPPORT = "support";
export const ROUTE_ABOUT_US = "about-us";
export const ROUTE_INDIVIDUAL_SUPPORT_REQUEST = "request";
export const ROUTE_ACADEMIC = "academic";
export const ROUTE_REDEEM_ACCESSCODE = "redeem-accesscode";
export const ROUTE_SUBMIT_REQUEST = "submit-request";
export const ROUTE_LESSON_REQUEST = "lesson-request";
export const ROUTE_PRIVACY_POLICY = "privacy-policy";
export const ROUTE_USER_ELUCIDATION = "user-elucidation";
export const ROUTE_TERMS_OF_USE = "terms-of-use-policy";
export const ROUTE_EC_EXPERIENCE = "ec-experience";
export const ROUTE_COOKIE_POLICY = "cookie-policy";
export const ROUTE_INVITE = "invite";
export const ROUTE_INVITATION = "invitation";
export const ROUTE_RTC_CLASSROOM = "classroom";
export const ROUTE_CHAT_ROLEPLAY = "chat-roleplay";
export const ROUTE_CHAT_WIZARD = "chat-wizard";
export const ROUTE_TRANSLATOR = "translator";
export const ROUTE_MEETING_ROOM = "meeting-room";
export const ROUTE_MOBILE_ZOOM_MEETING_ROOM = "zoom-meeting";
export const ROUTE_LEARNER_BLOG = "blog";
export const ROUTE_NOT_FOUND = "not-found";
export const ROUTE_UNSUBSCRIBE = "unsubscribe";
export const ROUTE_LEGAL = "legal";
export const ROUTE_MEDIATEST = "mediatest";

// Other Routes
export const ROUTE_JAL_LANDING = "japanairlines-landing";
export const ROUTE_MOBILE_LANDING_KOREA = "mobile-landing-ko";
export const ROUTE_MOBILE_LANDING_JAPAN: string = "mobile-landing-jp";
export const ROUTE_MOBILE_LANDING_KOREA_CHRISTMAS = "korea-christmas-2020";
export const ROUTE_LANDING_AI_SPEAKING_JAPAN = "landing-ai-speaking";
export const ROUTE_LANDING_GAMIFICATION_JAPAN = "landing-gamification";
export const ROUTE_KIDS = "kids";
export const ROUTE_HOME = "home";
export const ROUTE_TEACHER_TOOLS_SLUGS = {
    MANAGE: "manage",
    CURRICULUM: "curriculum",
    REPORTS: "reports",
    CLASS_TEST_REPORTS: "test-reports",
    PURCHASE: "purchase",
    DRILLDOWN: "drilldown",
    ORGANIZATION_DRILLDOWN: "organization-drilldown",
    HELP: "help"
};
export const ROUTE_CONVERSATIONS = "conversations";
export const ROUTE_MY_LESSONS = "my-lessons";
export const ROUTE_GROUP_LESSONS = "group-lessons";
export const ROUTE_TEACHER_SEARCH = "teacher-search";
export const ROUTE_MARKETING = "marketing";
export const ROUTE_CORPORATE = "corporate";
export const ROUTE_VI_LANDING = "vi-landing";
export const ROUTE_VI_LANDING_CAMPAIGN = "1videoperday";
export const ROUTE_ENGLISH_FOR_KIDS_LANDING = "english-for-kids";
export const ROUTE_BUSINESS_ENGLISH_LANDING = "business-english";
export const ROUTE_ACADEMIC_ENGLISH_LANDING = "academic-english";
export const ROUTE_DAILY_ENGLISH_LANDING = "daily-english";
export const ROUTE_AGORA_MEETING = "meeting";
export const ROUTE_VI_TELCO_SUBSCRIPTIONS = "telco-subscriptions";

export const ROUTE_PRE_LESSON = "pre-lesson";
export const ROUTE_LMS_REPORT: string = "lms-report";
