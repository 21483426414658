import { UNICESUMAR_CONFIG } from "./unicesumar.config";
import { UNIVALI_CONFIG } from "./univali.config";
import { UOL_CONFIG } from "./uol.config";
import { THY_CONFIG } from "./thy.config";
import { UPF_CONFIG } from "./upf.config";
import { AccountIdentity } from "../../../../core/identity.service";
import { ROCKFELLER_CONFIG } from "./rockfeller.config";
import { Partner } from "../../../../model/types/partner";
import { SHADOW_CONFIG } from "./shadow.config";
import { CATHO_CONFIG } from "./catho.config";
import { LANGRICH_CONFIG } from "./langrich.config";
import { SOL_CONFIG } from "./sol.config";
import { BOMJESUS_CONFIG } from "./bomjesus.config";
import { TELELANGUE_CONFIG } from "./telelangue.config";
import { AFYA_CONFIG } from "./afya.config";
import { BERLITZ_CONFIG } from "./berlitz.config";
import { FGW_CONFIG } from "./fgw.config";
import { VIDEOENGLISH_CONFIG } from "./videoenglish.config";
import { BRAZCUBAS_CONFIG } from "./brazcubas.config";
import { BERNOULLI_CONFIG } from "./bernoulli.config";
import { toNumber } from "lodash-es";
import { DESCOMPLICA_CONFIG } from "./descomplica.config";
import { TAI_CONFIG } from "./tai.config";
import { CLASSI_CONFIG } from "./classi.config";
import { ESTACIO_CONFIG } from "./estacio.config";
import { VIETTEL_CONFIG } from "./viettel.config";
import { SEIBIDO_CONFIG, SEIBIDO_COURSEONLY_CONFIG } from "./seibido.config";
import { REVELO_CONFIG } from "./revelo.config";
import { NECTAR_CONFIG } from "./nectar.config";
import { SATSI_CONFIG } from "./satsi.config";
import { JAPAN_CONFIG } from "./japan.config";
import { BEFREE_CONFIG } from "./befree.config";
import { CASATHOMASJEFERSON_CONFIG } from "./casathomasjeferson.config";
import { YOELGEVA_CONFIG } from "./yoelgeva.config";
import { GLOBED_CONFIG } from "./globed.config";
import { SHGM_CONFIG } from "./shgm.config";
import { GCT_CONFIG } from "./gct.config";
import { SEEDPR_CONFIG } from "./seedpr.config";
import { IGROUP_VIETNAM_CONFIG } from "./igroup-vietnam.config";
import { NORTHSUN_CONFIG } from "./northsun.config";
import { PartnerConfig } from "./partner-config";
import { EDITORA_FUTURA_CONFIG } from "./editora-futura.config";
import { VINUNI_CONFIG } from "./vinuni.config";
import { TOPCV_CONFIG } from "./topcv.config";
import { VLT_SITE_CONFIG } from "./vlt-site.config";
import { BAZA_VNPT_CONFIG } from "./baza-vnpt.config";

const DEFAULT_PARTNER_CONFIG = { styleClassName: "" };
const partnerIdConfigMap = new Map<number, PartnerConfig>([
    [Partner.PARTNER_ID_ENGLISHCENTRAL, DEFAULT_PARTNER_CONFIG],
    [Partner.PARTNER_ID_VNPT, DEFAULT_PARTNER_CONFIG],
    [Partner.PARTNER_ID_JAL, DEFAULT_PARTNER_CONFIG],
    [Partner.PARTNER_ID_UNICESUMAR, UNICESUMAR_CONFIG],
    [Partner.PARTNER_ID_UNIVALI, UNIVALI_CONFIG],
    [Partner.PARTNER_ID_UOL, UOL_CONFIG],
    [Partner.PARTNER_ID_TURKISH_AIRLINES, THY_CONFIG],
    [Partner.PARTNER_ID_TAI, TAI_CONFIG],
    [Partner.PARTNER_ID_ROCKFELLER, ROCKFELLER_CONFIG],
    [Partner.PARTNER_ID_UPF, UPF_CONFIG],
    [Partner.PARTNER_ID_CASATHOMASJEFERSON, CASATHOMASJEFERSON_CONFIG],
    [Partner.PARTNER_ID_YOELGEVA, YOELGEVA_CONFIG],
    [Partner.PARTNER_ID_YOELGEVA_MOE, YOELGEVA_CONFIG],
    [Partner.PARTNER_ID_TELELANGUE, TELELANGUE_CONFIG],
    [Partner.PARTNER_ID_TELELANGUE_DIRECT, TELELANGUE_CONFIG],
    [Partner.PARTNER_ID_BERLITZ, BERLITZ_CONFIG],
    [Partner.PARTNER_ID_SHADOW, SHADOW_CONFIG],
    [Partner.PARTNER_ID_CATHO, CATHO_CONFIG],
    [Partner.PARTNER_ID_BOMJESUS, BOMJESUS_CONFIG],
    [Partner.PARTNER_ID_AFYA, AFYA_CONFIG],
    [Partner.PARTNER_ID_FGW, FGW_CONFIG],
    [Partner.PARTNER_ID_VIDEOENGLISH, VIDEOENGLISH_CONFIG],
    [Partner.PARTNER_ID_BRAZCUBAS, BRAZCUBAS_CONFIG],
    [Partner.PARTNER_ID_BERNOULLI, BERNOULLI_CONFIG],
    [Partner.PARTNER_ID_DESCOMPLICA, DESCOMPLICA_CONFIG],
    [Partner.PARTNER_ID_ESTACIO, ESTACIO_CONFIG],
    [Partner.PARTNER_ID_VIETTEL, VIETTEL_CONFIG],
    [Partner.PARTNER_ID_SEIBIDO, SEIBIDO_CONFIG],
    [Partner.PARTNER_ID_SEIBIDO_COURSEONLY, SEIBIDO_COURSEONLY_CONFIG],
    [Partner.PARTNER_ID_GCT, GCT_CONFIG],
    [Partner.PARTNER_ID_REVELO, REVELO_CONFIG],
    [Partner.PARTNER_ID_NECTAR, NECTAR_CONFIG],
    [Partner.PARTNER_ID_SATSI, SATSI_CONFIG],
    [Partner.PARTNER_ID_BEFREE, BEFREE_CONFIG],
    [Partner.PARTNER_ID_JAPAN, JAPAN_CONFIG],
    [Partner.PARTNER_ID_SOL, SOL_CONFIG],
    [Partner.PARTNER_ID_LANGRICH, LANGRICH_CONFIG],
    [Partner.PARTNER_ID_CLASSI, CLASSI_CONFIG],
    [Partner.PARTNER_ID_GLOBED, GLOBED_CONFIG],
    [Partner.PARTNER_ID_GLOBED_2, GLOBED_CONFIG],
    [Partner.PARTNER_ID_SHGM, SHGM_CONFIG],
    [Partner.PARTNER_ID_SEEDPR, SEEDPR_CONFIG],
    [Partner.PARTNER_ID_IGROUP_VIETNAM, IGROUP_VIETNAM_CONFIG],
    [Partner.PARTNER_ID_NORTHSUN, NORTHSUN_CONFIG],
    [Partner.PARTNER_ID_EDITORA_FUTURA, EDITORA_FUTURA_CONFIG],
    [Partner.PARTNER_ID_VINUNI, VINUNI_CONFIG],
    [Partner.PARTNER_ID_TOPCV, TOPCV_CONFIG],
    [Partner.PARTNER_ID_VLT_SITE, VLT_SITE_CONFIG],
    [Partner.PARTNER_ID_BAZA_VNPT, BAZA_VNPT_CONFIG]
]);

export const getConfigByPartnerId = (partnerId?: number): PartnerConfig | undefined => {
    if (!partnerId) {
        return undefined;
    }
    return partnerIdConfigMap.has(partnerId) ? partnerIdConfigMap.get(partnerId) : undefined;
};

export const shouldPartnerGetRedirectedToMyRoutes = (partnerId: number) => {
    return getConfigByPartnerId(partnerId)?.allowRedirectionToMyRoutes ?? false;
};

export const shouldPartnerGetRedirectedToTeacherTools = (partnerId: number, accountIdentity: AccountIdentity): boolean => {
    if (!accountIdentity?.isTeacher) {
        return false;
    }
    return getConfigByPartnerId(partnerId)?.redirectToTeacherToolsOnLogin ?? false;
};

export const shouldPreventPartnerToReachMyClass = (partnerId: number): boolean => {
    return !(getConfigByPartnerId(partnerId)?.redirectToMyClassOnLogin ?? true);
};

export const isFooterTogglingDisabledForPartner = (partnerId: number): boolean => {
    return getConfigByPartnerId(partnerId)?.disableFooterToggling ?? false;
};

export const isHeaderRightNavsDisabledForPartner = (partnerId: number): boolean => {
    return getConfigByPartnerId(partnerId)?.disableHeaderRightNavs ?? false;
};

export const isBrowseVideosLinkInvisibleForPartnerPage = (partnerId: number): boolean => {
    return getConfigByPartnerId(partnerId)?.disableBrowseVideosLink ?? false;
};

export const isBrowseCoursesLinkInvisibleForPartnerPage = (partnerId: number): boolean => {
    return getConfigByPartnerId(partnerId)?.disableBrowseCoursesLink ?? false;
};

export const isBannerComponentsVisibleForPartner = (partnerId: number): boolean => {
    return getConfigByPartnerId(partnerId)?.showBannerComponents ?? false;
};

export const isAnonymousVideoAccessRestrictedForPartner = (partnerId: number): boolean => {
    return getConfigByPartnerId(partnerId)?.disableAnonVideoAccess ?? false;
};

export const isAnonymousCourseAccessRestrictedForPartner = (partnerId: number): boolean => {
    return getConfigByPartnerId(partnerId)?.disableAnonCourseAccess ?? false;
};

// Used for anonymous partner view. Logged in partner accounts use featureKnob for footer toggling
export const isFooterRestrictedForPartner = (partnerId: number): boolean => {
    return getConfigByPartnerId(partnerId)?.disableFooter ?? false;
};

export const getPartnerPaidLessonLength = (partnerId: number): number => {
    return getConfigByPartnerId(partnerId)?.paidLessonLength ?? 0;
};

export const getPartnerIdFromRootRoute = (rootRoute: string, rootRouteQueryParam?: string): number | undefined => {
    const partnerIdFromQueryParam = toNumber(rootRouteQueryParam);
    if (!!getConfigByPartnerId(partnerIdFromQueryParam)) {
        return partnerIdFromQueryParam;
    }
    return Array.from(partnerIdConfigMap.keys()).find((partnerId) => {
        return rootRoute?.includes(getConfigByPartnerId(partnerId).route);
    });
};

export const getAccountRegistrationTagIdFromRootRoute = (rootRoute: string): number | undefined => {
    const partnerId = getPartnerIdFromRootRoute(rootRoute);
    return getConfigByPartnerId(partnerId)?.accountRegistrationTagId;
};
