import { ROUTE_PARTNER_NECTAR } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const NECTAR_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_NECTAR,
    styleClassName: "partner-nectar",
    posterLink: "https://cdna.englishcentral.com/posters/nectar-poster.png",
    videoLink: "https://cdna.englishcentral.com/posters/nectar-intro-video.mp4",
    accessCodeImageLink: "https://cdna.englishcentral.com/promo/nectar/landpage-nectar.jpg",
    authenticationImageLink: "https://cdna.englishcentral.com/promo/nectar/landpage-nectar.jpg",
    accessCodeRedirectionDisabled: true,
    courseIds: [6126, 6127, 6128],
    registrationEnabled: false
};
